export enum ConnectorType {
  OAUTH_2 = 'OAUTH_2',
  TOKEN = 'TOKEN',
  BASIC_AUTH = 'BASIC_AUTH',
  SSH = 'SSH'
}

export interface ConnectorTypeMeta {
  title: string;
  description?: string;
  requiredParameters: { title: string, name: string, type: string, placeholder: string }[];
}
